<script>
	import { discountList, discountListContent } from "@/api/discount";
	export default {
		name:'DiscountDetail',
		data() {
			return {
                id:this.$route.params.id,
				contons:'',
				relatedImage:null,
                relatedTitle:null,

                header:{
                    mainTitle: getWord('promotion_details'),
                },
			};
		},
		components: {
			'mt-header': app.Mint.Header,
	        'mt-button': app.Mint.Button,
			Header: () => import('components/base/Header'),
		},
		methods: {
			loadCon(){
				var that=this;
				discountListContent(this.id).then(result => {
					if (result.data.code == "SUCCESS") {
                        this.contons = result.data.result.content;

                        discountList().then(result => {
		                    if (result.data.code == "SUCCESS") {
		                        this.relatedImage = result.data.result.filter(item=>{
		                        	return item.id.toString()===that.$route.params.id
		                        })[0].img
                                this.relatedTitle = result.data.result.filter(item=>{
                                    return item.id.toString()===that.$route.params.id
                                })[0].title
        					}else{
                                app.Mint.Toast(result.data.msg);
                            }
                        })
					}else{
						this.$message.error(result.data.msg);
					}
				})
			}
		},
		
		mounted() {
			this.loadCon();
		}
	}
</script>
<template>
	<div id="promotion" :class="_TEMPLATE">
		<template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
			<mt-header :title="getWord('promotion_centre')">
	            <router-link to="/discount" slot="left">
					<mt-button icon="back"></mt-button>
				</router-link>
	        </mt-header>
	        <img :src="relatedImage" />
	        <div id="content">	        
				<div v-html="contons" ></div>
			</div>
		</template>

		<template v-if="_TEMPLATE==='template-3'">
			<Header 
                :mainTitle="header.mainTitle" 
                :backButton=false 
            />
            <div id="content">
	        	<img :src="relatedImage" />
	        	<h4>{{relatedTitle}}</h4>
				<article v-html="contons"></article>
			</div>
		</template>
	</div>
</template>
<style lang="scss" scoped>
#promotion {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {

    	#content {

    		> img {
    			max-height: 1.6rem;
    		}

    		> h4 {
    			color: #5F646E;
    			font-size: .36rem;
    			font-weight: normal;
    		}
    	}
    }

    > img {
    	height: 1.96rem;
    }

	#content {
		overflow: auto;
    	height: 100%;
    	padding: .25rem;
		word-break:break-all;
    	/deep/ img {
    		max-width: 100%;
    		max-height: 100%;
    	}
	}
}
</style>
