import { service } from '../CONF.js'

//优惠活动列表
export function discountList() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/Home/discount')
        }))
    })
}
//优惠活动跳转
export function discountListContent(photoId) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/Home/content',{
                params: {
                    id: photoId
                }
            })
        }))
    })
}

//点击签到
export function userSign() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post('/Main/UserSign/userSign')
        }))
    })
}

//本月会员签到信息
export function signList() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/UserSign/signList')
        }))
    })
}